.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s linear;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s linear, opacity 1s linear;
}