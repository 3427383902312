.button_google{
    color:#037A9A;
    width:25vw;
    height:2.083vw;
    border:0;
    border-radius: 0.417vw;
    background: #FD5A22;
    cursor: pointer;
}

.input{
    color:#07294c;
    width:25vw; 
    height:2.083vw;
    border:0;
    border-radius: 0.417vw;
    padding: 0 1.042vw;
    font-weight: 400;
}

.input::placeholder{
    color: #037A9A;
}

.input:focus{
    outline: none;
}

.button_sign{
    margin-top: 1.563vw;
    font-weight: 700;
    padding-top: 0.25vw;
    color:#07294C;
    width:25vw;
    height:2.083vw;
    border:0;
    border-radius: 0.417vw;
    background: #00C8D5;
}